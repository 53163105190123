import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import appTheme from 'utils/theme';
import storeWrapper from 'core/lib/frameworks/redux';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { END } from 'redux-saga';
import { getToken } from 'utils/storage';
import { useDispatch, useSelector } from 'react-redux';
import {
  getProfile,
  getQuestionTypesCategorized,
} from 'core/lib/adapters/redux/actions';
import i18n from 'utils/i18n';
import 'components/Payment/StripGateway/style.css';

import Notifier from 'components/Notifier';
import LoadingIndicator from 'components/LoadingIndicator';
import EduBenchmarkHoc from 'components/EduBenchmarkHoc';
import { useRouter } from 'next/dist/client/router';
import { setToken } from '../utils/storage';
import storage from 'core/lib/services/storage';

const MyApp = ({ Component, pageProps }) => {
  const router = useRouter();
  const { query } = router;
  const auth = useSelector((state) => state.auth);
  const profile = useSelector((state) => state.profile);
  const categorized = useSelector((state) => state.questions.categorized);
  const dispatch = useDispatch();
  const token = getToken();
  const [initializing, setInitializing] = useState(true);
  const [stripePromise, setStripePromise] = useState(() =>
    loadStripe(process.env.STRIPE_KEY),
  );
  useEffect(() => {
    if (query?.token) {
      setToken(query?.token)
      storage().setToken(query?.token);
      dispatch(getProfile(query?.token));
    }
  }, [query?.token])

console.log(
  "initializing",initializing,profile.isAuthenticating,!auth.isAuth, (profile.isAuthenticating && !auth.isAuth), Object.keys(categorized).length === 0,initializing ||
  (profile.isAuthenticating && !auth.isAuth) ||
  Object.keys(categorized).length === 0
);
  useEffect(() => {
    setInitializing(false);
    dispatch(getQuestionTypesCategorized());
    // debugger
    if (token && !auth.isAuth) {
      dispatch(getProfile({ token }));
    }

    if ('serviceWorker' in navigator) {
      const path = window.location.pathname.split('/');
      navigator.serviceWorker
        .register(
          path.length === 2
            ? './firebase-messaging-sw.js'
            : `${'../'.repeat(path.length - 2)}firebase-messaging-sw.js`,
        )
        .then((registration) => {
          // messaging.useServiceWorker(registration);
          // messaging
          //   .requestPermission()
          //   .then(async () => {
          //     const firebaseToken = await messaging.getToken();
          //     return firebaseToken;
          //   })
          //   .then((firebaseToken) => {
          //     console.log(`firebase token is: ${firebaseToken}`);
          //   })
          //   .catch((err) => {
          //     console.log('Unable to get permission to notify.', err);
          //   });
          // navigator.serviceWorker.addEventListener('message', (message) =>
          //   console.log(message),
          // );
        })
        .catch((err) => {
          console.log('Service worker registration failed, error:', err);
        });
    }
  }, [token]);

  return (
    <EduBenchmarkHoc title={pageProps?.title ? pageProps?.title : "Home"} description={pageProps?.description ? pageProps?.description : "test"}>
      <ThemeProvider theme={appTheme}>
        <Elements stripe={stripePromise}>
          <SnackbarProvider maxSnack={3}>
            <Notifier />
            <CssBaseline />
            {initializing ||
              (profile.isAuthenticating && !auth.isAuth) ||
              Object.keys(categorized).length === 0 ? (
              <LoadingIndicator />
            ) : (
              <Component {...pageProps} />
            )}
             {/* {
             Object.keys(categorized).length > 0 ? (
              <Component {...pageProps} />
             )
             : (<LoadingIndicator/>)
             } */}
          </SnackbarProvider>
        </Elements>
      </ThemeProvider>
    </EduBenchmarkHoc>
  );
};

export const getServerSideProps = storeWrapper.getServerSideProps(
  async ({ store }) => {
    store.dispatch(END);
    await store.sagaTask.toPromise();
    return {
      props: {},
    };
  },
);

MyApp.propTypes = {
  Component: PropTypes.func.isRequired,
  pageProps: PropTypes.shape().isRequired,
};
export default storeWrapper.withRedux(MyApp);
